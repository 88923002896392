import React from "react";
import { List, Datagrid, TextField, TextInput, Filter, DateField, 
         Show, SimpleShowLayout, useListContext, DateTimeInput,required, maxValue, CheckboxGroupInput,
         useRecordContext,
         ArrayField} from "react-admin"
import {Table, TableRow, TableHead, TableCell, TableBody} from "@mui/material"

import DocumentRedownloadButton from "../components/DocumentRedownloadButton";

const now = new Date().getTime();
const maxDate = new Date(now - (now %86400000) + 86400000);
const validate_start = [required()]
const DocumentsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Customer UUID/Key" source="uuid" sx={{width: 175, marginRight: -1}} alwaysOn/>
        <TextInput label="Sender Email" source="sender_email" sx={{width: 175, marginRight: -1}} alwaysOn/>
        <TextInput label="Recipient Email" source="recipient_email" sx={{width: 175, marginRight: -1}} alwaysOn/>
        <DateTimeInput label="Start Date" source="start_date" validate={validate_start} sx={{width: 175, marginRight: -1}} alwaysOn/>
        <DateTimeInput label="End Date" source="end_date" validate={maxValue(maxDate)} sx={{width: 175}} alwaysOn/>
        <CheckboxGroupInput source="downloaded_documents" choices={[ {id: 'true', name: 'Only Downloaded Documents'}]} alwaysOn/>
    </Filter>
);

export const DocumentsShow = (props) => {
    return(
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="document_uuid"/>
                <DateField source="created_at"/>
                <DateField source="updated_at"/>
                <DateField source="integration_file_generated_at"/>
                <TextField source="driver_email"/>
                <TextField source="recipient_email"/>
                <TextField souce="file_format"/>
                <DateField source="downloaded_via_ftp_at"/>
                <TextField source="document_status"/>
            </SimpleShowLayout>
        </Show>
    );
}

const ExpandableInfo = () => {
    let record = useRecordContext()
    console.log(record);
   return(
        <Table size="small">
            <TableHead >
                <TableRow style={{backgroundColor:'darkgrey'}}>
                    <TableCell />
                    <TableCell style={{fontWeight:'bold'}}>Driver Sent At</TableCell> 
                    <TableCell style={{fontWeight:'bold'}}>BOL #</TableCell> 
                    <TableCell style={{fontWeight:'bold'}}>Version Scanned On</TableCell> 
                    <TableCell style={{fontWeight:'bold'}}>Number Of Pages</TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow >
                    <TableCell/>
                    <TableCell>{ record.driver_sent_at }</TableCell> 
                    <TableCell>{ record.bill_of_lading_number }</TableCell> 
                    <TableCell>{ record.version_scanned_on }</TableCell> 
                    <TableCell>{ record.num_pages }</TableCell> 
                </TableRow>
            </TableBody>
        </Table>

   ) 
}

const CustomDocumentsList = (props) =>{
    const { filterValues } = useListContext(props);
    const  documentListError  = localStorage.getItem('documentListError');
    if(documentListError != null){
        localStorage.removeItem('documentListError');
        switch(documentListError){
            case "Invalid Sender Email":
                return(
                    <h2 align='center' style={{margin:35, padding:10}}>Error 404: Invalid Sender Email</h2>
                )
            case "Invalid Customer UUID":
                return(
                    <h2 align='center' style={{margin:35, padding:10}}>Error 404: Invalid Customer UUID</h2>
                )
            case "Invalid Client Key": 
                return(
                    <h2 align='center' style={{margin:35, padding:10}}>Error 404: Invalid Client Key</h2>
                )
            case "Internal Server Error":
                return(
                    <h2 align='center' style={{margin:35, padding:10}}>Error 500: Internal Server Error</h2>
                )
            case "Date Range Too Large":
                return(
                    <><h2 align='center' style={{ margin: 35, padding: 10 }}>Error 400: Date Range Too Large</h2><h2 align='center' style={{ margin: 35, padding: 10 }}>Date Range Must Be 30 Days</h2></>
                )
            case "Bad Request":
                return(
                    <h2 align='center' style={{margin:35, padding:10}}>Error 400: Bad Request</h2>
                )
            default: 
                return(
                    <h2 align='center' style={{margin: 35, padding: 10}}>No documents found</h2>
                );

        }
    }
    else if((filterValues.sender_email == undefined) && (filterValues.uuid == undefined) && (filterValues.recipient_email == undefined)){
        return(
            <>
            <h2 align='center' style={{ margin: 35, padding: 10 }}>Fill one or more of the fields to retrieve documents</h2>
            <h2 align='center' style={{ margin: 35, padding: 10 }}>Use Client Key or UUID if you want to mark documents for redownload</h2>
            </>
        );
    }
    if(filterValues.uuid == undefined){
        return (
            <Datagrid key={props.key} bulkActionButtons={false} size="small" expand={<ExpandableInfo/>}>
                <TextField source="#"/>
                <TextField source="document_uuid" sx={{ whiteSpace:'nowrap'}}/>
                <DateField source="created_at"/>
                <DateField source="updated_at"/>
                <DateField source="integration_file_generated_at"/>
                <TextField source="driver_email"/>
                <TextField source="recipient_email"/>
                <TextField souce="file_format"/>
                <DateField source="downloaded_via_ftp_at"/>
                <TextField source="document_status"/>
            </Datagrid>
        );
    }
    else{
        return (
            <Datagrid key={props.key} bulkActionButtons={< DocumentRedownloadButton />} size="small" expand={<ExpandableInfo/>}>
                <TextField source="#"/>
                <TextField source="document_uuid"/>
                <DateField source="created_at"/>
                <DateField source="updated_at"/>
                <DateField source="integration_file_generated_at"/>
                <TextField source="driver_email"/>
                <TextField source="recipient_email"/>
                <TextField souce="file_format"/>
                <DateField source="downloaded_via_ftp_at"/>
                <TextField source="document_status"/>
            </Datagrid>
        );
    }
}
var curr = new Date()
var start = new Date()
start.setDate(start.getDate() -30 )
export const DocumentsList = (props) => (
    <List {...props} pagination={false} bulkActionButtons={false} filters={<DocumentsFilter/>} exporter={false} filterDefaultValues={{start_date:start, end_date: curr}}>
        <CustomDocumentsList {...props}/>
    </List>
)